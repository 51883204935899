import React from 'react'
import './VideoEditing.css';
import { motion } from 'framer-motion';
import Svideoediting from './Coursesimg/Video Editing (1).png'
const VideoEditing = () => {
  return (
    <motion.div className='VideoEditing'
    initial={{width:0}}
    animate={{width:"100%"}}
    exit={{x: window.innerWidth, transition: {duration:0.3}}}
    >
      <div className='Vmain'>
      <div className="Scontact" id='Svideoediting'><div className="stikybox"><h3>Video Editing</h3></div><img src={Svideoediting} alt="" /></div>
   </div>
    </motion.div>
  )
}

export default VideoEditing