import React from 'react'
import './Error.css'
const Errorpage = () => {
  return (
    <div className='Ermain'>

       <h2>404 Page not found!</h2>
    </div>
  )
}

export default Errorpage