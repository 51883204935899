// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './Mainslider.css'



// import required modules
import {Autoplay, Pagination, Navigation } from "swiper";

import slideimg1 from '../image/Student1.png'
// import slideimg2 from '../image/Student2.png'
import slideimg3 from '../image/Student3.png'
// import slideimg4 from '../image/Student4.png'
// import slideimg5 from '../image/Student5.png'
import slideimg6 from '../image/Student6.png'

let firstimg='https://lh3.googleusercontent.com/p/AF1QipMxTVPKFJ0gKDo-nl1-lqsVsrzm9bCqGuCYmh0T=s680-w680-h510';
let secondimg='https://lh3.googleusercontent.com/p/AF1QipPEDXYEP_Dcy8LsvMu3W1eHXUqGffdCubrqSqT8=s680-w680-h510';
let thirdimg='https://lh3.googleusercontent.com/p/AF1QipM0c8eYyO-cx7C_vNg0E1nN3xnQNIFoByoNdGA9=s680-w680-h510';

export default function Mainslider() {
  return (
    <>
      <Swiper
        effect={"flip"}
        grabCursor={true}
        pagination={false}
        // navigation={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
        // modules={[Autoplay,EffectFlip, Pagination, Navigation]}


       
      
        navigation={false}
      


        modules={[Autoplay, Pagination, Navigation]}
       
        className="mySwiper"
      >
        <SwiperSlide >
          <img src={firstimg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slideimg1} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={secondimg} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slideimg3} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={thirdimg}  alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slideimg6} alt="" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
