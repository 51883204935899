import React from 'react'
import './Certificate.css'

const Certificate = () => {
  return (
    <div className='maincertificate'>
        <a href='https://docs.google.com/forms/d/e/1FAIpQLSe3JPR61uhPRtrFX2NQ-9DCDGYd0N0dYEGq3bgCm4erQ3mtzg/viewform'>Apply Certificate</a>
    </div>
  )
}

export default Certificate