const CoursesData = [
  {
    title: "Digital Marketing",
    cduration: "| 3 Months",
    iconimg: "Images/social-media.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/DigitalMarketing",
  },
  {
    title: "Diploma",
    cduration: "| 6-12 Months",
    iconimg: "./Images/certificate.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/ComputerDiploma",
  },

  {
    title: "e-Accounting",
    cduration: "| 6-12 Months",
    iconimg: "./Images/budgeting.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/Accounting",
  },

  {
    title: "Basic Computer",
    cduration: "| 3 Months",
    iconimg: "./Images/data-science.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/BasicComputer",
  },

  {
    title: "Advanced Excel",
    cduration: "| 2 Months",
    iconimg: "./Images/excel.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/AdvancedExcel",
  },

  {
    title: "Tally With GST",
    cduration: "| 3 Months",
    iconimg: "Images/Tally-Prime-Logo.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/TallyWithGST",
  },
  {
    title: "Graphic Design",
    cduration: "| 6 Months",
    iconimg: "Images/layers.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/GraphicDesigning",
  },
  {
    title: "Video Editing",
    cduration: "| 3 Months",
    iconimg: "Images/premiere-pro.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/VideoEditing",
  },
  {
    title: "Web Design",
    cduration: "| 6 Months",
    iconimg: "Images/web-design.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/WebDesigning",
  },
  {
    title: "Programing Language",
    cduration: "| 3-12 Months",
    iconimg: "Images/coding-language.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/Programming",
  },
  {
    title: "AutoCAD 2D ",
    cduration: "| 2 Months",
    iconimg: "Images/Auto1.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/AutoCad2D",
  },
  {
    title: "3Ds Max + V-ray",
    cduration: "| 3 Months",
    iconimg: "Images/threeds.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/ThreeDsMaxVray",
  },
  {
    title: "Google Sketch up",
    cduration: "| 2 Months",
    iconimg: "Images/GoogleS.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/GoogleSketchup",
  },
  {
    title: "Spoken English",
    cduration: "| 6 Months",
    iconimg: "Images/writing.png",
    cName: "sbox",
    cicon: "subicon",
    ctitle: "subtitle",
    carro: "arro",
    pnavigate: "/SpokenEnglish",
  },
];
export { CoursesData };
