import React from "react";
import "./MSectionHeading.css";
import Mainslider from "./Mainslider";
// import macimg from '../HomeComponents/HomeImage/mac.png'
// import { MenuData1 } from '../MenuData1';
import "../MenuData1";
import { useNavigate } from "react-router-dom";
const MSectionHeading = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mainboxofhero">
        <div className="mainchildbox1">
          <h2>
            {" "}
            <span className="highlight">8 Years Of Excellence,</span> Thousands
            Of Happy Students!
          </h2>
          <h3>
            {" "}
            We are one of the leading computer institutes in Chhattarpur and
            Khanpur New Delhi established in 2014 and approved by MSME
          </h3>
          <article>
            {" "}
            AI for creative Professionals | Basic Computer | DCA | ADCA | Tally
            | AutoCad | Graphics Design | Web Design | UI/UX Design | Full Stack
            Development | Motion Graphics | Digital Marketing
          </article>
        </div>
        <h2 className="branchheading">Center's</h2>
        <div className="branch">
          {/* <button href='https://www.google.com/maps/dir//Main+Rd,+Chattarpur+South,+Chhatarpur,+New+Delhi,+Delhi+110074/@28.5004607,77.1016512,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390d1feb3f4b96cf:0x46ab086a7b2233b1!2m2!1d77.1840526!2d28.5004855?entry=ttu' className='btn' id='chhattapurbutton'><i class="fa-solid fa-location-dot"></i>&nbsp; Chhattarpur</button> */}
          <a
            href="https://www.google.com/maps/dir//Main+Rd,+Chattarpur+South,+Chhatarpur,+New+Delhi,+Delhi+110074/@28.5004607,77.1016512,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390d1feb3f4b96cf:0x46ab086a7b2233b1!2m2!1d77.1840526!2d28.5004855?entry=ttu"
            className="btn"
            id="chhattapurbutton"
          >
            <i class="fa-solid fa-location-dot"></i>&nbsp; Chhattarpur
          </a>
          <a
            href="https://www.google.com/maps/dir//189,+1st+Floor+Devli+Road,+South,+Khanpur,+New+Delhi,+Delhi+110080/@28.5131014,77.1503152,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390ce176d28c6d87:0xf4a802e90928148!2m2!1d77.2327166!2d28.5131263?entry=ttu"
            className="btn"
            id="chhattapurbutton"
          >
            <i class="fa-solid fa-location-dot"></i>&nbsp; Khanpur
          </a>
          {/* <button className='btn' onClick={()=>{navigate('/Khanpur');}}><i class="fa-solid fa-location-dot"></i>&nbsp; Khanpur</button> */}
        </div>
        <div className="mainchildbox">
          {/* <img src={macimg} alt="" /> */}
          <Mainslider />
        </div>

        <div className="loginform">
          <form action="" className="linput">
            <input type="text" name="" id="" placeholder="Username" />
            <br />
            <input type="password" name="" id="" placeholder="Password" />
            <button className="btn">Log-in</button>
            <button className="btn">Register</button>

            <button
              className="btn"
              id="certificate"
              onClick={() => {
                navigate("/Certificate");
              }}
            >
              Certificate
            </button>
            <a
              href="https://forms.gle/HX93YKQodvrckdqB9"
              className="btn"
              id="certificate"
            >
              Registration Form
            </a>
          </form>
        </div>
      </div>

      <div className="mhead">
        <h2>
          <span className="ourbest">Our Best Professional</span> Certifications
          To Get a High Paying Job.
        </h2>
      </div>
    </>
  );
};

export default MSectionHeading;
