import React from 'react'
import './EFooter.css';


const EFooter = () => {
  return (
    <>
    
    <footer className='footer'>
      <div className="ffooter">
        <div className="fbox"><h3>Trending Courses</h3><br />
       
        Basic Computer | Diploma in Computer Application | Advanced Diploma in Computer Application | e-Accounting | Tally Prime With GST | Busy Accounting |
Advanced Excel | VBA with Macro | Graphic Designing | Motion Graphic | Animatiion | Video Editing | Web Designing
     
        
        
        </div>
        <div className="fbox">


     <h3>  Why Choose Us?</h3><br />

     Mastering the art of learning is fundamental for a student's achievements in both academics and life. As we navigate the Digital Age, which profoundly influences learning methods and future opportunities, it's crucial for students to embrace this rapidly evolving world. We stand alongside students as their dedicated companions in perpetual learning, preparing them effectively for the challenges and possibilities of tomorrow.        </div>
        <div className="fbox">

      <h3>  Certification</h3><br />

When the student successfully completes a course. we provide a valid completion/Diploma certificate by MSME.
        </div>
        <div className="fbox">



       <h3> Contact</h3><br />
    
+91 8744897916
+91 9911772652 <br />

Email id:  <a href="mailto:eightacademy8@gmail.com" style={{textDecoration:'none',color:'white'}}>eightacademy8@gmail.com</a>

<br />Address: D-5/2, Main Road Chhattarpur
Near Apex Hospital (Agarwal Sweets Building)
Near Patanjali New Delhi-110074

        </div>
        </div>
        {/* <div className="Aboutbox">
        <h3>About us</h3>
        <span>We are one of the leading computer institutes in Chhattarpur New Delhi established in 2014 and approved by MSME, Our mission is to work effectively towards enhancing skills and knowledge utilizing the existing educational and training courses to provide the needs of the job market in New Delhi. Eight Academy Computer Education & Training Institute aims to serve the private and government sectors in the fields of training and education through highly qualified trainers is our vision. Trust, commitment, smart technology, accountability, and highly qualified staff are our core values.</span>
      </div> */}
      <div className="mobilesticky">
      <a href="tel:87448 97916" style={{textDecoration:'none',color:'white'}}> <i class="fa-solid fa-phone"></i></a><br />
       <a href='https://wa.me/918744897916?text=' style={{textDecoration:'none',color:'white'}}> 
       <i class="fa-brands fa-whatsapp"></i></a><br />
        <i class="fa-solid fa-envelope"></i>
       
      </div>
    </footer>
    </>
  );
}

export default EFooter