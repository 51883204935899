import React from 'react'
import '../Pages/Stickycontact.css'
const Stickycontact = () => {
  return (
    <div className='mainsticky'>
        <div className="stickycontact">
        <a href="tel:87448 97916" style={{textDecoration:'none'}}> <i class="fa-solid fa-phone"></i></a><br />
       <a href='https://wa.me/918744897916?text=' style={{textDecoration:'none'}}> 
       <i class="fa-brands fa-whatsapp"></i></a><br />
        <i class="fa-solid fa-envelope"></i>
        </div>

        {/* <div className="stickycontactphone">
        <a href="tel:87448 97916" style={{textDecoration:'none'}}> <i class="fa-solid fa-phone"></i></a><br />
       <a href='https://wa.me/919955869645?text=Thank you for contacting Eight Academy Computer Education! Please let us know how we can help you.' style={{textDecoration:'none'}}> 
       <i class="fa-brands fa-whatsapp"></i></a><br />
        <i class="fa-solid fa-envelope"></i>
        </div> */}
    </div>
  )
}

export default Stickycontact