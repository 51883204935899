import React from 'react'
import "./Khanpur.css"

const Khanpur = () => {
  return (
    <div className='kmainbox' >
<h3>Eight Acadmey</h3>
    </div>
  )
}

export default Khanpur