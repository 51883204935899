// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import herore from '../Carousel/greviewimg/herore.png'
// import dr from '../Carousel/greviewimg/dr.png'
// import secondreviewimg from './greviewimg/secondreviewimg.png'
// import thirdreviewimg from './greviewimg/thirdreviewimg.png'
// import forthreviewimg from './greviewimg/forthreviewimg.png'
// import fifthreviewimg from './greviewimg/fifthreviewimg.png'
// import sixthreviewimg from './greviewimg/sixthreviewimg.png'
// import seventhreviewimg from './greviewimg/sevenreviewimg.png'
import firstreviewimg from './greviewimg/firstreviewimg1.png'
import eighthreviewimg from './greviewimg/eighthreviewimg.png'
import ninthreviewimg from './greviewimg/ninthhreviewimg.png'
import tenthreviewimg from './greviewimg/tenthhreviewimg.png'
import salonitoamr from './greviewimg/saloni tomar.png'
import tannu from './greviewimg/tannu.png'
import fabeha from './greviewimg/fabeha.png'
import sagar from './greviewimg/sagar.png'
import vibha from './greviewimg/vibha.png'
import nisha from './greviewimg/nisha.png'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./googleS.css";

// import required modules
import {Autoplay, Pagination, Navigation } from "swiper";

export default function GoogleS() {
  return (
    <>
    <div className="googlemain">
        <h2><span className="ourstudent">Our Student's</span> Love Us...</h2>
        <h3>We take immense pleasure in introducing our accomplished students who have secured promising positions. Take a moment to hear their thoughts on their experience at the academy.</h3>
    </div>
      <Swiper




        slidesPerView={1}

        breakpoints={{
            0: {
              slidesPerView: 1,
            },
            400:{
              slidesPerView:1,
            },
            639: {
              slidesPerView: 2,
            },
            865:{
              slidesPerView:2
            },
            1000:{
              slidesPerView:3
            },
            1500:{
              slidesPerView:3
            },
            1700:{
              slidesPerView:3
            }
          }}


        spaceBetween={0}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay,Pagination, Navigation]}
        
        className="mySwiper"
      >
        
        <SwiperSlide className="Rmain">
        <div className="rimg"><img src={firstreviewimg} alt="" /></div>
            <div className="Rbox" id="firstreview" >
            <h3>Anjali</h3>
            <span>Basic & Advanced Excel</span>
            <hr />
            <div className="reviewcontant"> Eight academy is a very great academy there teaching methods is great they always coperate with students. In this academy they develop personality also and education system is so good their teachers is also very intelligent and understanding.</div>  </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={salonitoamr} alt="" /></div>
            <div className="Rbox" id="secondreview" >
            <h3>Saloni Tomar</h3>
            <span>Accounting</span>
            <hr />
            <div className="reviewcontant"> Amazing experience with eight academy....I have done 3 months tally prime with gst. Great teachers with great learning experience... I'll do more courses with eight academy soon...very friendly teachers are here check it out here for many computer course..</div>  </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={tannu} alt="" /></div>
            <div className="Rbox" id="thirdreview">
            <h3>Tannu</h3>
            <span>ADCA</span>
            <hr />
            <div className="reviewcontant"> All sir are very kind , specially Sarfraz sir and this academy is very good in chhattarpur area. And my overall experience are excellent And I thank all the mentors..</div> </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={sagar} alt="" /></div>
            <div className="Rbox" id="forthreview" >
            <h3>Sagar</h3>
            <span>Graphic's Designer</span>
            <hr />
            <div className="reviewcontant"> Eight academy is the best academy for learning computer ..  in academy the teachers are fabulous the way of teaching is really good his. Teaching methods are. Superb .. I like the way of teaching of sarfaraj sir .</div> </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={fabeha} alt="" /></div>
            <div className="Rbox" id="fifthreview">
            <h3>Fabeha</h3>
            <span>Auto CAD 2D & 3D</span>
            <hr />
            <div className="reviewcontant"> I have completed AutoCAD course from Eight Academy, teaching method is Superb, explains all concepts very well and Sarfaraj sir is so Humble.
Regards.</div> </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={vibha} alt="" /></div>
            <div className="Rbox" id="sixthreview">
            <h3>Vibha</h3>
            <span>App Development's</span>
            <hr />
            <div className="reviewcontant"> This academy is full knowledge full academy
Eight academy is the best academy in chhatupur. Whatever children come, they definitely learn something and go away. A lot of computers are taught here..</div> </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={nisha} alt="" /></div>
            <div className="Rbox" id="seventhreview">
            <h3>Nisha</h3>
            <span>Digital Marketing</span>
            <hr />
            <div className="reviewcontant"> As a student of eight Acadamy I learnt a lot and their Saturday session are amazing.  Facilities are supportive and always available to solve any queries . It is best computer institute in chhattarpur new delhi..</div></div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={eighthreviewimg} alt="" /></div>
            <div className="Rbox" id="eighthreview">
            <h3>Chanchal kaur</h3>
            <span>English Spoken</span>
            <hr />
            <div className="reviewcontant"> Eight academy is a very great academy there teaching methods is great they always coperate with students. In this academy they develop personality also and education system is so good their teachers is also very intelligent and understanding.</div>  </div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={ninthreviewimg} alt="" /></div>
            <div className="Rbox" id="ninthreview">
            <h3>sahil qureshi</h3>
            <span>Python Developer's</span>
            <hr />
            <div className="reviewcontant"> Eight Academy is good for thos students who have future Goel in computer graphics and language..</div></div></SwiperSlide>
             <SwiperSlide className="Rmain">
        <div className="rimg"><img src={tenthreviewimg} alt="" /></div>
            <div className="Rbox" id="tenthreview">
            <h3>Pooja kundra</h3>
            <span>AutoCad</span>
            <hr />
            <div className="reviewcontant"> Positive: Communication, Professionalism, Quality, Value
I'm taking AutoCAD classes...the way of teaching ossam... In my opinion Best Designing institute in Chhatarpur...thank u  so much sir.</div> </div></SwiperSlide>
        

        
       
        {/* <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide> */}
      </Swiper>
    </>
  );
}
